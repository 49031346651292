import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import IntroCopy from "../components/IntroCopy";
import ServiceInfo from "../components/ServiceInfo";
import ImageGallery from "../components/ImageGallery";

const ServicesPage = ({ data }) => {
  return (
    <Layout>
      <main>
        <SEO title="Services" />
        <IntroCopy
          headline="Our Services"
          body="We provide a comprehensive range of roofing services to meet your demands. From tiling to slating, flat roofing to uPVC fascias, soffits and guttering, leadwork to chimney services, complete re-roofs to small repairs, our staff have the neccessary skills for even the most demanding of problems."
          topPadding
        />
        <ImageGallery
          images={data.images.nodes.filter(image => image.name === "image1")}
        />
        <ServiceInfo
          image={data.images.nodes.filter(image => image.name === "image2")}
          headline="Tiling & Slating"
          body="At Agace Bros Roofing we undertake all forms of tiling and slating from strip and reroofs to simple repairs. Our trained staff combine both traditional and modern techniques to get the best results for your roof."
          linkTo="/portfolio/tiling-and-slating"
          linkCopy="See some examples"
        />
        <ServiceInfo
          image={data.images.nodes.filter(image => image.name === "image3")}
          headline="Flat Roofing"
          body="We can offer several flat roofing options, including 3 Layer cross bonded bitumen flat roofs, EPDM rubber roofing, Lead bays, and fibreglass systems. So we can provide you with the best option for your specific needs."
          linkTo="/portfolio/flat-roofing"
          linkCopy="See some examples"
        />
        <ServiceInfo
          image={data.images.nodes.filter(image => image.name === "image4")}
          headline="Leadwork"
          body="From Chimney flashing to multiple lead bays we have the knowledge, skills and understanding to undertake any form of leadwork."
          linkTo="/portfolio/leadwork"
          linkCopy="See some examples"
        />
        <ServiceInfo
          image={data.images.nodes.filter(image => image.name === "image5")}
          headline="Fascias, Soffits and Guttering"
          body="Available in multiple designs and colours we can offer a wide range of options for your roofing plastics all carried out to the highest professional standards."
          linkTo="/portfolio/upvc-facias-soffits-and-guttering"
          linkCopy="See some examples"
        />
        <ServiceInfo
          image={data.images.nodes.filter(image => image.name === "image6")}
          headline="And plenty more..."
          body="Chimney Services, Roofing Repairs, Repointing, Sun Pipes Fitted, Sky Lights Fitted, Velux Windows Fitted, Storm Damage Insurance work."
          linkTo="/portfolio"
          linkCopy="View our portfolio"
        />
      </main>
    </Layout>
  );
};

export const query = graphql`
  query ServiceImages {
    images: allFile(filter: { relativeDirectory: { eq: "services" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default ServicesPage;
