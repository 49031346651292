import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

import Container from "../Container";

const StyledServiceInfo = styled.div`
  padding: 25px 0;

  @media only screen and (min-width: 740px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 100px 0;
    border-bottom: 1px solid #e4e4e4;
    min-height: 500px;
  }

  @media only screen and (min-width: 920px) {
  }

  .col {
    width: 100%;

    @media only screen and (min-width: 740px) {
      width: 50%;
      padding: 0 40px;
    }

    @media only screen and (min-width: 920px) {
    }

    .img-holder {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .img-wrapper {
        width: 100%;
        position: relative;
        display: flex;

        @media only screen and (min-width: 740px) {
          max-width: 500px;
          width: 100%;
        }

        @media only screen and (min-width: 920px) {
        }

        .dummy {
          margin-top: 66%;
        }

        .gatsby-image-wrapper,
        img {
          position: absolute !important;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        img {
          object-fit: cover;
        }
      }
    }
  }
`;

const ServiceInfo = ({ headline, body, linkTo, linkCopy, image }) => {
  return (
    <Container>
      <StyledServiceInfo>
        <div className="col">
          <h2>{headline}</h2>
          <p>{body}</p>
          {linkTo && linkCopy && <Link to={linkTo}>{linkCopy}</Link>}
        </div>
        <div className="col">
          <div className="img-holder">
            <div className="img-wrapper">
              <div className="dummy" />
              {image.map(img => (
                <Img fluid={img.childImageSharp.fluid} />
              ))}
            </div>
          </div>
        </div>
      </StyledServiceInfo>
    </Container>
  );
};

ServiceInfo.defaultProps = {
  headline: "",
  body: "",
  linkTo: null,
  linkCopy: null,
};

export default ServiceInfo;
